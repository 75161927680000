import { MEMBER_TYPE_CD } from '@/constants';

export const pageRoutes = {
  dashboard: {
    main: { path: '/', title: '메인' },
  },
  auth: {
    signIn: { path: '/auth', title: '로그인/회원가입' },
    signUp: { path: '/auth/sign-up', title: '회원가입' },
    myPage: { path: '/test', title: '마이페이지' },
    complete: { path: '/auth/sign-up/complete', title: '회원가입 완료' },
    find: { path: '/auth/find', title: '이메일 | 비밀번호 찾기' },
    findResult: {
      path: '/auth/find/result',
      title: '이메일 | 비밀번호 찾기 결과',
    },
  },
  my: {
    main: {
      path: '/my',
      title: '계정관리',
    },
    review: {
      path: '/my/review',
      title: '리뷰관리',
      memberType: MEMBER_TYPE_CD.GENERAL.value,
    },
    corporate: {
      path: '/my/corporate',
      title: '업체 정보 관리',
      memberType: MEMBER_TYPE_CD.AGENCY.value,
    },
  },
  advCorporate: {
    main: {
      path: '/adv-corporates/list',
      title: '광고 업체 DB',
    },
    detail: {
      path: (corporateNo: unknown) => `/adv-corporate/${corporateNo}`,
      title: '광고 업체 상세',
    },
    review: {
      path: (corporateNo: unknown) => `/adv-corporate/review/${corporateNo}`,
      title: '광고 업체 리뷰',
    },
  },
  fraud: {
    main: {
      path: '/ad-fraud',
      title: '광고사기사례 DB',
    },
    detail: {
      path: (advFraudNo: unknown) => `/ad-fraud/${advFraudNo}`,
      title: '광고사기사례 상세',
    },
    report: {
      path: `/ad-fraud/report`,
      title: '광고사기사례 제보',
    },
  },
  performance: {
    main: {
      path: '/ad/performance',
      title: '광고 성과 분석',
    },
  },
  data: {
    uploadFile: {
      path: '/data/upload-file',
      title: '데이터 추가',
    },
    dashboard: {
      path: '/data/dashboard',
      title: '대시보드',
    },
  },
  logout: {
    main: {
      path: '/',
      title: '로그아웃',
    },
  },
} as const;

import { css } from '@emotion/react';
import { STLCOLOR } from '@storelink-io/storelink-ui/src/styles/GlobalStyleVariables';

export const cssTabStyle = css`
  &.ant-tabs {
    .ant-tabs-tab + .ant-tabs-tab {
      margin: 0 0 0 16px;
    }

    .ant-tabs-nav {
      margin: 0 0 24px 0;

      .ant-tabs-nav-wrap .ant-tabs-nav-list {
        .ant-tabs-tab {
          .ant-tabs-tab-btn {
            //color: ${STLCOLOR.gray3};
            font-weight: 500;
          }
        }

        .ant-tabs-tab.ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            //color: ${STLCOLOR.blue};
            font-weight: 700;
          }
        }

        .ant-tabs-tab.ant-tabs-tab-disabled {
          .ant-tabs-tab-btn {
            //color: ${STLCOLOR.gray4};
            font-weight: 500;
          }
        }
      }
    }
  }
`;

export const cssButtonTypeStyle = css`
  &.ant-tabs {
    .ant-tabs-nav::before {
      border: none !important;
    }
    .ant-tabs-tab {
      color: ${STLCOLOR.gray1} !important;
      background: transparent !important;
      border: none !important;
      height: auto;
      padding: 0 !important;
      margin-right: 8px !important;

      .ant-tabs-tab-btn {
        padding: 4px 16px 4px 24px !important;
      }

      /* 탭 버튼 사이 공백 추가 */
      &:not(:first-of-type) {
        margin-left: 8px !important;
      }

      /* 탭 버튼 사이 라인 추가 */
      &::before {
        content: ' ';
        border-right: 1px solid ${STLCOLOR.gray4} !important;
        height: 15px;
        margin-left: -8px;
      }
      /* 활성화 된 탭 버튼 */
      &.ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: ${STLCOLOR.gray1} !important;
        }
        background: ${STLCOLOR.gray5} !important;
        border-radius: 5px !important;
      }

      /* 비활성화 된 탭 버튼 */
      &.ant-tabs-tab-disabled {
        .ant-tabs-tab-btn {
          color: ${STLCOLOR.gray5} !important;
        }
      }
    }
  }
`;

import {
  useFetch,
  useLoadMore,
  usePost,
} from '@storelink-io/storelink-core/api/hooks';

import type {
  IAdvCorporate,
  IAdvCorporateListRequest,
  IAdvCorporateResponse,
  IGetAdvCorporateReviewRequest,
  IAdvCorporateReviewResponse,
  IPostAdvCorporateReviewRequestBody,
  IPostAdvCorporateResponse,
} from '@/api/interface';
import { apiRoutes } from 'routes';

export const useLoadMoreAdvCorporateList = (
  params: IAdvCorporateListRequest
) => {
  return useLoadMore<IAdvCorporate>(apiRoutes.getAdvertiseCorporates, params);
};

export const useFetchAdvCorporateDetail = (corporateNo: number) => {
  return useFetch<IAdvCorporateResponse>(
    apiRoutes.getAdvertiseCorporateDetail,
    { corporateNo },
    { enabled: !!corporateNo }
  );
};

export const useFetchAdvCorporateReviews = (
  params: IGetAdvCorporateReviewRequest
) => {
  return useFetch<IAdvCorporateReviewResponse>(
    apiRoutes.getAdvertiseCorporateReviews,
    params,
    {
      enabled: !!params.corporateNo,
    }
  );
};

export const usePostAdvCorporateReview = () => {
  return usePost<IPostAdvCorporateReviewRequestBody, IPostAdvCorporateResponse>(
    apiRoutes.postAdvertiseCorporateReview
  );
};

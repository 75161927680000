import { AlignRightOutlined } from '@ant-design/icons';
import { signOut } from '@storelink-io/storelink-core/api/requests';
import { TokenUtils } from '@storelink-io/storelink-core/utils/token-utils';
import { AntdButton } from '@storelink-io/storelink-ui';
import { Layout, Drawer, Grid, Dropdown, Menu, Space } from 'antd';
import Link from 'next/link';
import { useRouter } from 'next/router';
import type { FC } from 'react';

import { useCallback, useMemo, useState } from 'react';
import {
  cssDrawerStyle,
  cssHeaderStyle,
  cssHeaderStyleWithFullWidth,
} from '@/components/layout/MainLayout.styles';
import { pageRoutes } from 'routes';
import { AdLinkLogo } from '../logo/AdLinkLogo';

const { Header } = Layout;

type TopMenuCategoryType = {
  kind: keyof typeof pageRoutes;
  title?: string;
  hasChild?: boolean;
};

const topMenuCategory: TopMenuCategoryType[] = [
  {
    kind: 'advCorporate',
  },
  {
    kind: 'fraud',
  },
  {
    kind: 'performance',
  },
];

if (TokenUtils.getUserInfo()) {
  topMenuCategory.push({
    kind: 'my',
    title: '마이 페이지',
    hasChild: true,
  });
  topMenuCategory.push({
    kind: 'logout',
    title: '로그아웃',
  });
} else {
  topMenuCategory.push({
    kind: 'auth',
  });
}

const mobileTopMenuCategory = [
  { path: pageRoutes.advCorporate.main.path, title: '광고업체DB' },
  { path: pageRoutes.fraud.main.path, title: '광고사기예방' },
  { path: pageRoutes.performance.main.path, title: '광고성과분석' },
];

interface IMainHeaderProps {
  fullWidth?: boolean;
}

export const MainHeader: FC<IMainHeaderProps> = ({ fullWidth }) => {
  const router = useRouter();
  const { useBreakpoint } = Grid;
  const { md } = useBreakpoint();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleClickDrawerOpen = useCallback(() => {
    setDrawerOpen((prev) => !prev);
  }, []);

  // eslint-disable-next-line
  const topMenuItems = useMemo<any[]>(() => {
    return topMenuCategory.map(({ kind, title, hasChild }) => {
      if (hasChild) {
        return {
          key: kind,
          label: title,
          children: Object.values(pageRoutes[kind]).map((route) => {
            const menuUserType = route.memberType;
            const userType = TokenUtils.getUserInfo()?.memberTypeCd;
            const pathname =
              typeof route.path === 'function' ? route.path() : route.path;
            if (menuUserType === userType || !menuUserType) {
              return {
                key: pathname,
                label: <Link href={pathname}>{route.title}</Link>,
              };
            }
          }),
        };
      } else {
        const route = Object.values(pageRoutes[kind])[0];
        const label = title ?? route.title;
        return {
          key: route.path,
          label: label,
        };
      }
    });
  }, []);

  return (
    <Header css={fullWidth ? cssHeaderStyleWithFullWidth : cssHeaderStyle}>
      <AdLinkLogo width={82} height={25} />
      {md ? (
        <div className="storelink-menu">
          {topMenuItems?.map(({ key, label, children }) => {
            if (children) {
              // 마이 페이지
              return (
                <Dropdown key={label} overlay={<Menu items={children} />}>
                  <Space>
                    <Link href="/my">마이 페이지</Link>
                  </Space>
                </Dropdown>
              );
            } else if (label === '로그아웃') {
              return (
                <a
                  href="#"
                  key={label}
                  onClick={async (e) => {
                    e.preventDefault();
                    await signOut(key);
                  }}
                >
                  로그아웃
                </a>
              );
            } else if (label === '로그인/회원가입') {
              return (
                <AntdButton
                  key={key}
                  type="primary"
                  onClick={() => router.push(key)}
                >
                  로그인/회원가입
                </AntdButton>
              );
            } else {
              return (
                <Link key={label} href={key}>
                  {label}
                </Link>
              );
            }
          })}
        </div>
      ) : (
        <>
          <Drawer
            open={drawerOpen}
            placement="right"
            width={240}
            onClose={handleClickDrawerOpen}
            css={cssDrawerStyle}
          >
            <ul>
              {mobileTopMenuCategory.map(({ path, title }) => (
                <li key={path}>
                  <Link href={path}>{title}</Link>
                </li>
              ))}
            </ul>
          </Drawer>
          <AntdButton
            type="text"
            icon={<AlignRightOutlined />}
            onClick={handleClickDrawerOpen}
          />
        </>
      )}
    </Header>
  );
};

import { Button } from 'antd';
import type { ButtonProps } from 'antd';
import type { FC } from 'react';
import React, { useMemo } from 'react';
import { cssAntdButtonStyle } from './AntdButton.styles';

type Props = ButtonProps & {
  width?: number;
};

export const AntdButton: FC<Props> = ({ width, ...rest }) => {
  const style = useMemo(() => {
    if (width) {
      return {
        minWidth: width,
      };
    }
  }, [width]);
  return (
    <Button
      css={cssAntdButtonStyle}
      data-testid="antd-button"
      style={style}
      {...rest}
    />
  );
};

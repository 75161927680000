import type { AxiosInstance, AxiosRequestConfig } from 'axios';
import axios from 'axios';
import { TokenUtils } from '../utils/token-utils';
import { HOST_SIGN_OUT_REDIRECT } from './hosts';
import { callRefreshToken } from './requests';

export interface CustomAxiosInstance extends AxiosInstance {
  getUri(config?: AxiosRequestConfig): string;

  request<T>(config: AxiosRequestConfig): Promise<T>;

  get<T>(url: string, config?: AxiosRequestConfig): Promise<T>;

  delete<T>(url: string, config?: AxiosRequestConfig): Promise<T>;

  head<T>(url: string, config?: AxiosRequestConfig): Promise<T>;

  options<T>(url: string, config?: AxiosRequestConfig): Promise<T>;

  post<T>(url: string, data?: unknown, config?: AxiosRequestConfig): Promise<T>;

  put<T>(url: string, data?: unknown, config?: AxiosRequestConfig): Promise<T>;

  patch<T>(
    url: string,
    data?: unknown,
    config?: AxiosRequestConfig
  ): Promise<T>;
}

const headers = {
  json: { 'content-type': 'application/json' },
  multipart: { 'content-type': 'multipart/form-data' },
} as const;

const request: CustomAxiosInstance = axios.create({
  withCredentials: true,
  timeout: 1000000,
  headers: headers.json,
});

request.interceptors.request.use((req) => {
  if (TokenUtils.getAccessToken()) {
    req.headers.set('X-AUTH-TOKEN', TokenUtils.getAccessToken());
  }
  return req;
});

type SubscriberHandlerType = (accessToken: string) => void;
let isTokenRefreshing = false;
let refreshSubscribers: SubscriberHandlerType[] = [];

const onTokenRefreshed = (
  accessToken: Parameters<SubscriberHandlerType>[0]
) => {
  refreshSubscribers.forEach((callback) => callback(accessToken));
  refreshSubscribers = [];
};

const addRefreshSubscriber = (callback: SubscriberHandlerType) => {
  refreshSubscribers.push(callback);
};

request.interceptors.response.use(
  (response) => {
    // return response.data instanceof Blob ? response : response.data;
    return response.headers['content-type'] === 'application/pdf'
      ? response
      : response.data;
  },
  async (error) => {
    const {
      config,
      response: { status },
    } = error;

    const originalRequest = config;
    if (status === 401) {
      const retryOriginalRequest = new Promise((resolve) => {
        addRefreshSubscriber((accessToken) => {
          originalRequest.headers['X-AUTH-TOKEN'] = accessToken;
          resolve(request(originalRequest));
        });
      });

      if (
        error.response.data.messageCode === 'AUTH_002' || // 유효하지 않은 토큰입니다.
        error.response.data.messageCode === 'AUTH_004' // 만료된 refresh 토큰입니다.
      ) {
        TokenUtils.removeAllToken();

        if (HOST_SIGN_OUT_REDIRECT) {
          window.location.href = HOST_SIGN_OUT_REDIRECT;
        }

        // refresh token 만료 시 특정 요청에 refresh 토큰 만료에 대한 에러 리턴
        return Promise.reject(error);
      }
      if (!isTokenRefreshing) {
        isTokenRefreshing = true;

        const { result } = await callRefreshToken<{ registerSite: string }>(
          TokenUtils.getToken()
        );

        if (result) {
          const { token, refreshToken, registerSite, ...userInfo } = result;
          TokenUtils.removeAllToken();
          TokenUtils.setToken({ token, refreshToken, registerSite, userInfo });
          isTokenRefreshing = false;
          axios.defaults.headers.common['X-AUTH-TOKEN'] = result.token;

          onTokenRefreshed(result.token);
        }
      }

      return retryOriginalRequest;
    }
    return Promise.reject(error);
  }
);

export default request;

export const api = {
  get: <T>(url: string, params?: object) => request.get<T>(url, { ...params }),
  post: <T>(url: string, data: unknown, config?: AxiosRequestConfig) =>
    request.post<T>(url, data, config),
  patch: <T>(url: string, data: unknown) => request.patch<T>(url, data),
  put: <T>(url: string, data: unknown) => request.put<T>(url, data),
  delete: <T>(url: string) => request.delete<T>(url),
  postDownload: <T>(url: string, data: unknown) =>
    request.post<T>(url, data, {
      responseType: 'blob',
    }),
  postFile: <T>(url: string, data: unknown) =>
    request.post<T>(url, data, {
      headers: headers.multipart,
    }),
  putFile: <T>(url: string, data: unknown) =>
    request.put<T>(url, data, {
      headers: headers.multipart,
    }),
  patchFile: <T>(url: string, data: unknown) =>
    request.patch<T>(url, data, {
      headers: headers.multipart,
    }),
};

import { Skeleton } from 'antd';
import type { FC, PropsWithChildren, MouseEvent } from 'react';
import { useMemo } from 'react';
import type { fontStyle } from './StorelinkText.styles';
import {
  fontStyle as fontStyleValue,
  StorelinkH1,
  StorelinkH2,
  StorelinkH3,
  StorelinkH4,
  StorelinkP,
  TextSkeletonContainer,
} from './StorelinkText.styles';

type KindType = keyof (typeof fontStyle)['kind'];
type ColorType = keyof (typeof fontStyle)['color'];
type weightType = keyof (typeof fontStyle)['weight'];

type ChildrenProps = {
  underline?: boolean;
  code?: boolean;
  mark?: boolean;
  keyboard?: boolean;
  del?: boolean;
  italic?: boolean;
  skeletonWidth?: string;
  skeletonMinWidth?: string;
};

export type StorelinkTextProps = {
  kind?: KindType | number;
  color?: ColorType;
  weight?: weightType | number;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
  isLoading?: boolean;
};

export const StorelinkText: FC<
  PropsWithChildren<StorelinkTextProps & ChildrenProps>
> = ({
  kind = 'p1',
  color = 'black',
  weight = 'semiBold',
  isLoading = false,
  underline,
  code,
  mark,
  keyboard,
  del,
  italic,
  skeletonWidth,
  skeletonMinWidth,
  children,
  ...rest
}) => {
  const TextComponent = useMemo(() => {
    switch (kind) {
      case 'h1':
        return StorelinkH1;
      case 'h2':
        return StorelinkH2;
      case 'h3':
        return StorelinkH3;
      case 'h4':
        return StorelinkH4;
      default:
        return StorelinkP;
    }
  }, [kind]);

  const childrenWrap = useMemo(() => {
    let wrapper = children;
    if (underline) {
      wrapper = <u>{wrapper}</u>;
    }
    if (code) {
      wrapper = <code>{wrapper}</code>;
    }
    if (mark) {
      wrapper = <mark>{wrapper}</mark>;
    }
    if (keyboard) {
      wrapper = <kbd>{wrapper}</kbd>;
    }
    if (del) {
      wrapper = <del>{wrapper}</del>;
    }
    if (italic) {
      wrapper = <i>{wrapper}</i>;
    }
    return wrapper;
  }, [children, underline, code, mark, keyboard, del, italic]);

  const TextSkeleton = () => {
    return (
      <TextSkeletonContainer width={skeletonWidth} minWidth={skeletonMinWidth}>
        <Skeleton.Input
          active
          style={{
            height:
              fontStyleValue.kind[kind as keyof typeof fontStyle.kind].fontSize,
            width: '100%',
            minWidth: '100%',
          }}
        />
      </TextSkeletonContainer>
    );
  };

  return (
    <>
      {isLoading ? (
        <TextSkeleton />
      ) : (
        <TextComponent
          kind={kind}
          color={color}
          weight={weight}
          isLoading={isLoading}
          {...rest}
        >
          {childrenWrap}
        </TextComponent>
      )}
    </>
  );
};

import { TokenUtils } from '../../utils/token-utils';
import {
  HOST_REFRESH_TOKEN_API,
  HOST_SIGN_IN_API,
  HOST_SIGN_OUT_API,
} from '../hosts';
import type {
  IRefreshTokenRequest,
  IRefreshTokenResponse,
  ISignInResponse,
} from '../interface';
import request from '../request';

export const signIn = async (data: unknown) => {
  TokenUtils.removeAllToken();
  return request
    .post<ISignInResponse<{ registerSite: string }>>(
      `${HOST_SIGN_IN_API}`,
      data
    )
    .then((res) => {
      if (res?.result) {
        const { token, refreshToken, registerSite, ...userInfo } = res.result;
        TokenUtils.setToken({ token, refreshToken, registerSite, userInfo });
        return Promise.resolve(res);
      }
      return Promise.resolve(res);
    });
};

export const signOut = (
  redirect?: string,
  { token, refreshToken }: IRefreshTokenRequest = TokenUtils.getToken()
) => {
  return request
    .post(
      `${HOST_SIGN_OUT_API}`,
      {},
      {
        headers: {
          'X-AUTH-TOKEN': token,
          'X-AUTH-REFRESHTOKEN': refreshToken,
        },
      }
    )
    .then(() => {
      TokenUtils.removeAllToken();
      if (redirect) {
        window.location.href = redirect;
      }
    });
};

export const callRefreshToken = <T>(
  { token, refreshToken }: IRefreshTokenRequest = TokenUtils.getToken()
) => {
  if (token === undefined || refreshToken === undefined) {
    return Promise.reject(new Error('token or refreshToken is undefined'));
  }
  return request.post<IRefreshTokenResponse<T>>(
    `${HOST_REFRESH_TOKEN_API}`,
    {},
    {
      headers: {
        'X-AUTH-TOKEN': token,
        'X-AUTH-REFRESHTOKEN': refreshToken,
      },
    }
  );
};

import { useFetch, usePost } from '@storelink-io/storelink-core/api/hooks';
import { apiRoutes } from 'routes';
import type {
  ISmsAuthReqRequest,
  ISmsAuthReqResponse,
  ISmsAuthConfirmRequest,
  ISmsAuthConfirmResponse,
  IGetCompanyListResponse,
  IGetCompanyListRequest,
  IPostMemberRegistersRequest,
  IPostMemberRegistersResponse,
  IGetMemberMemberidsRequest,
  IGetMemberMemberidsResponse,
  IPostMemberPasswordRequest,
  IPostMemberPasswordResponse,
} from '../interface';

export const usePostSmsAuthRequest = () => {
  return usePost<ISmsAuthReqRequest, ISmsAuthReqResponse>(
    apiRoutes.postSmsAuthRequest
  );
};

export const usePostSmsAuthConfirm = () => {
  return usePost<ISmsAuthConfirmRequest, ISmsAuthConfirmResponse>(
    apiRoutes.postSmsAuthConfirm
  );
};

export const useGetCompanyList = (params?: IGetCompanyListRequest) => {
  return useFetch<IGetCompanyListResponse>(apiRoutes.getCompanyList, params, {
    enabled: false,
  });
};

export const usePostMemberRegisters = () => {
  return usePost<IPostMemberRegistersRequest, IPostMemberRegistersResponse>(
    apiRoutes.postMemberRegisters
  );
};

export const useGetMemberMemberids = (params?: IGetMemberMemberidsRequest) => {
  return useFetch<IGetMemberMemberidsResponse>(
    apiRoutes.getMemberMemberids,
    params,
    {
      enabled: false,
    }
  );
};

export const usePostMemberPassword = () => {
  return usePost<IPostMemberPasswordRequest, IPostMemberPasswordResponse>(
    apiRoutes.postMemberPassword
  );
};

export type AdvCorporateListViewType = 'all' | 'kakao' | 'naver' | 'adlink';
export type AdvCorporateReviewSortType = 'new' | 'good' | 'bad';

export const ADV_CORPORATE_LIST_VIEW_TYPE = {
  ALL: 'all',
  KAKAO: 'kakao',
  NAVER: 'naver',
  ADLINK: 'adlink',
} as const;

export const ADV_CORPORATE_REVIEW_SORT_TYPE = {
  NEW: 'new',
  GOOD: 'good',
  BAD: 'bad',
} as const;

import { css } from '@emotion/react';
import { Tabs as AntdTabs } from 'antd';
import type { TabsProps } from 'antd';
import type { SizeType } from 'antd/lib/config-provider/SizeContext';
import type { ReactNode, FC } from 'react';
import { useMemo } from 'react';
import { cssButtonTypeStyle, cssTabStyle } from './Taps.styles';

export type TabList = {
  tabName: string | ReactNode;
  tabKey: number | string;
  tabContent?: ReactNode;
  disabled?: boolean;
};

type TabProps = TabsProps & {
  TabList?: Array<TabList>;
  ButtonType?: boolean;
  type?: 'line' | 'card';
  size?: SizeType;
};

export const Tabs: FC<TabProps> = (props) => {
  const {
    TabList,
    type = 'line',
    size = 'small',
    ButtonType = false,
    ...rest
  } = props;

  /* ButtonType 일경우 type 'card'로 고정 - 스타일 이슈 */
  let setType = type;
  if (ButtonType) setType = 'card';

  const items = useMemo(() => {
    if (TabList?.length) {
      return TabList.map(({ tabName, tabKey, tabContent, disabled }) => ({
        label: tabName,
        key: `${tabKey}`,
        children: tabContent,
        disabled,
      }));
    }
  }, [TabList]);

  return (
    <AntdTabs
      type={setType}
      size={size}
      css={css([cssTabStyle, ButtonType && cssButtonTypeStyle])}
      items={items}
      {...rest}
    />
  );
};

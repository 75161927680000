import Cookies from 'js-cookie';

type TokenParamsType = {
  token?: string;
  refreshToken?: string;
  userInfo?: object;
  registerSite: string;
};

const accessTokenName = process.env.NEXT_PUBLIC_ACCESS_TOKEN_NAME ?? 'token';
const refreshTokenName =
  process.env.NEXT_PUBLIC_REFRESH_TOKEN_NAME ?? 'refreshToken';
const userInfoName = process.env.NEXT_PUBLIC_USER_INFO_NAME ?? 'userInfo';
const refererCookieName = `${accessTokenName.split('_')[0] ?? ''}_referer`;

const cookieOptions = {
  // ...(accessTokenName.includes('prod') && {
  //   domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN ?? '.storelink.io', // to remove possible
  // }),
  expires: 365,
} as const;

export class TokenUtils {
  static getCookieOptions() {
    // TODO: 추후 쿠키 도메인, 쿠키명 변경 협의시 수정 필요
    return {
      ...cookieOptions,
      domain: `${
        new URL(process.env.NEXT_PUBLIC_HOST || window.location.hostname)
          .hostname
      }`,
    };
  }

  static getCookieDomain() {
    return this.getCookieOptions().domain;
  }

  static getTokenName() {
    return { accessTokenName, refreshTokenName, userInfoName };
  }

  static setToken({ token, refreshToken, userInfo }: TokenParamsType) {
    if (token) {
      Cookies.set(accessTokenName, token, { ...cookieOptions });
    }
    if (refreshToken) {
      Cookies.set(refreshTokenName, refreshToken, { ...cookieOptions });
    }
    if (userInfo) {
      Cookies.set(userInfoName, JSON.stringify(userInfo), { ...cookieOptions });
    }
  }

  static getToken() {
    const token = Cookies.get(accessTokenName) as string;
    const refreshToken = Cookies.get(refreshTokenName) as string;
    return { token, refreshToken };
  }

  static removeAllToken() {
    Cookies.remove(accessTokenName);
    Cookies.remove(refreshTokenName);
    Cookies.remove(userInfoName);
    Cookies.remove('recentSearchedKeywords');
  }

  static getAccessToken() {
    return Cookies.get(accessTokenName) ?? '';
  }

  static getUserInfo() {
    let userInfo;
    const strUserInfo = Cookies.get(userInfoName);
    if (strUserInfo) {
      userInfo = JSON.parse(strUserInfo);
    }
    return userInfo;
  }

  static isExpireRefreshToken() {
    const { expiration } = this.getUserInfo() ?? { expiration: 0 };

    return expiration - Date.now() <= 0;
  }

  static getRefererCookieName() {
    return refererCookieName;
  }

  static getRefererCookieValue() {
    return Cookies.get(refererCookieName) ?? '';
  }
}

import { css } from '@emotion/react';
import {
  MEDIA,
  STLCOLOR,
} from '@storelink-io/storelink-ui/styles/GlobalStyleVariables';

export const cssLayoutStyle = css`
  width: 1024px;
  min-height: 100vh;
  margin: 0 auto;
  padding-bottom: 50px;
  ${MEDIA.default} {
    padding: 0 16px 50px;
  }
  ${MEDIA.md} {
    width: 100%;
  }
`;

export const cssLayoutWithFullWidthStyle = css`
  ${cssLayoutStyle}
  width: 100%;
  padding-bottom: 50px;
  ${MEDIA.default} {
    padding: 0;
    padding-bottom: 50px;
  }
`;

export const cssHeaderStyle = css`
  & .ant-menu-horizontal {
    border-bottom: 0;
    justify-content: right;
    & .ant-menu-item-selected {
      &::after {
        border-bottom: 0;
      }
    }
    & > li * {
      font-weight: 500;
    }
  }
  margin: 40px 0 58px 0;
  ${MEDIA.md} {
    margin: 24px 0 30px 0;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: max-content;
  gap: 56px;
  .ant-btn + .ant-dropdown-trigger {
    margin-left: 29px;
  }

  .storelink-menu {
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    flex-grow: 1;
    gap: 24px;
    color: ${STLCOLOR.gray2};
    &,
    button,
    & div.ant-dropdown-trigger a {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
    border-bottom: none;
  }
  &.ant-layout-header {
    padding-inline: 0;
    height: 22px;
    line-height: 22px;
  }
`;

export const cssHeaderStyleWithFullWidth = css`
  ${cssHeaderStyle}
  width: 1024px;
  margin: 40px auto 40px auto;
  ${MEDIA.default} {
    padding: 0 16px;
  }
  ${MEDIA.md} {
    width: 100%;
    margin: 24px auto 24px auto;
  }

  &.ant-layout-header {
    padding-inline: 0;
    height: 22px;
    line-height: 22px;
  }
`;

export const cssFooterStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 52px;
  padding: 0;
  margin-top: 120px !important;
  letter-spacing: -0.03em;
  .tiny-font span {
    font-size: 11px;
  }
  &.ant-layout-footer {
    padding: 0;
  }
  .footer-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    div > span:first-of-type {
      margin-right: 10px;
    }
  }
  .footer-right {
    text-align: right;
    line-height: 16.5px;
  }
`;

export const cssFooterStyleWithFullWidth = css`
  ${cssFooterStyle}
  width: 1024px;
  margin-left: auto;
  margin-right: auto;
  ${MEDIA.md} {
    width: 100%;
    margin: 24px auto 24px auto;
  }
`;

export const cssInnerLayoutStyle = css`
  display: flex;
  flex-direction: row;
  ${MEDIA.md} {
    flex-direction: inherit;
  }
`;

export const cssInnerAuthLayoutStyle = css`
  display: flex;
  align-self: center;
  width: 340px;
  height: max-content;
  ${MEDIA.md} {
    width: 100%;
  }
`;

export const cssLeftStyle = css`
  background-color: #fff;
  margin-right: 16px;

  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);

  & .ant-menu-item * {
    color: #333333;
    font-size: 14px;
    font-weight: 700;
  }
  & .ant-menu-inline {
    border-right: 0;
  }

  ${MEDIA.md} {
    display: none;
    margin-right: 0;
  }
`;

export const cssLeftTabStyle = css`
  display: none;
  ${MEDIA.md} {
    display: inline-block;
  }
`;

export const cssLeftMenuStyle = css`
  height: 100%;
  & .ant-menu-item {
    height: 54px;
    padding-left: 16px !important;
    svg {
      margin-right: 8px;
    }
  }
  //&:has(>.ant-menu-item-only-child) {
  //  padding-left: 46px !important;
  //}
  .ant-menu-item::after {
    display: none;
  }
  .ant-menu-item-selected {
    background-color: #f0fafe !important;
  }
`;

export const cssMembershipBtnStyle = css`
  height: 34px;
  background: #f2f2f2;
  vertical-align: middle;
  border: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
`;

export const cssDrawerStyle = css`
  .ant-drawer-content-wrapper {
    .ant-drawer-header {
      padding: 60px 16px 20px 0;
      .ant-drawer-header-title {
        justify-content: flex-end;
        button {
          margin: 0;
          width: 24px;
          height: 24px;
          color: ${STLCOLOR.black};
        }
      }
    }
    .ant-drawer-body {
      padding: 20px 24px;
      ul {
        padding-inline-start: 0;
        li {
          color: ${STLCOLOR.gray1};
          font-size: 14px;
          font-weight: 700;
          line-height: 22px;
          cursor: pointer;
        }
        li + li {
          margin-top: 16px;
        }
      }
    }
  }
`;

export const cssLoginLayoutStyle = css`
  background-color: #fff;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

export const cssLoginContentStyle = css`
  width: 340px;
`;

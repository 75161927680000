import Image from 'next/image';
import { useRouter } from 'next/router';
import type { FC } from 'react';
import { StyledLogo } from '@/components/logo/AdlinkLogo.styles';
import AdLinkLogoImg from '@/public/assets/images/logo/adlink-logo.png';
import { pageRoutes } from 'routes/pageRoutes';

type Props = {
  width?: number;
  height?: number;
  label?: string;
};

export const AdLinkLogo: FC<Props> = ({ width, height }) => {
  const router = useRouter();
  return (
    <StyledLogo>
      <Image
        src={AdLinkLogoImg}
        width={width}
        height={height}
        quality="100"
        onClick={() => router.push(pageRoutes.dashboard.main.path)}
        alt="logo"
      />
    </StyledLogo>
  );
};

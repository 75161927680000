import { useFetch, usePost } from '@storelink-io/storelink-core/api/hooks';
import { apiRoutes } from 'routes/apiRoutes';
import type {
  IPostMemberWithdrawRequest,
  IPostMemberWithdrawResponse,
  IPostMemberMobileRequest,
  IPostMemberMobileResponse,
  IPostMemberEmailRequest,
  IPostMemberEmailResponse,
  IMemberCorporateResponse,
  IPostMemberCorporateRequest,
  IPostMemberCorporateResponse,
  IPostMemberConfirmRequest,
  IPostMemberConfirmResponse,
} from '../interface';

export const usePostMemberWithdraw = () => {
  return usePost<IPostMemberWithdrawRequest, IPostMemberWithdrawResponse>(
    apiRoutes.postMemberWithdraw
  );
};

export const usePostMemberMobile = () => {
  return usePost<IPostMemberMobileRequest, IPostMemberMobileResponse>(
    apiRoutes.postMemberMobile
  );
};

export const usePostMemberEmail = () => {
  return usePost<IPostMemberEmailRequest, IPostMemberEmailResponse>(
    apiRoutes.postMemberEmail
  );
};

export const useFetchMemberCorporate = () => {
  return useFetch<IMemberCorporateResponse>(apiRoutes.getMemberCorporate, {});
};

export const usePostMemberCorporate = () => {
  return usePost<IPostMemberCorporateRequest, IPostMemberCorporateResponse>(
    apiRoutes.postMemberCorporate
  );
};

export const usePostMemberConfirm = () => {
  return usePost<IPostMemberConfirmRequest, IPostMemberConfirmResponse>(
    apiRoutes.postMemberConfirm
  );
};

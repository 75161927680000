import { Grid, Layout } from 'antd';
import type { FC, PropsWithChildren } from 'react';
import { MainHeader } from '@/components/layout/MainHeader';
import {
  cssInnerLayoutStyle,
  cssLayoutStyle,
  cssLayoutWithFullWidthStyle,
  cssInnerAuthLayoutStyle,
} from '@/components/layout/MainLayout.styles';
import { MainLeft } from '@/components/layout/MainLeft';
import type { pageRoutes } from 'routes';
import { MainFooter } from './MainFooter';

const { Content } = Layout;

export type MainLayoutProps = PropsWithChildren & {
  kind?: 'main' | 'left' | 'auth';
  leftKind?: keyof typeof pageRoutes;
  fullWidth?: boolean;
  hideFooter?: boolean;
};

export const MainLayout: FC<MainLayoutProps> = ({
  kind = 'main',
  children,
  leftKind = 'auth',
  fullWidth = false,
  hideFooter = false,
}) => {
  const { useBreakpoint } = Grid;
  const { md } = useBreakpoint();

  return (
    <Layout css={fullWidth ? cssLayoutWithFullWidthStyle : cssLayoutStyle}>
      <MainHeader fullWidth={fullWidth} />
      <Layout
        hasSider={md}
        css={kind === 'auth' ? cssInnerAuthLayoutStyle : cssInnerLayoutStyle}
      >
        {kind === 'left' && <MainLeft leftKind={leftKind} />}
        <Content className="children-layout">{children}</Content>
      </Layout>
      {!hideFooter && <MainFooter fullWidth={fullWidth} />}
    </Layout>
  );
};

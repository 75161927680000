import styled from '@emotion/styled';

export const StyledLogo = styled('div')`
  display: flex;
  justify-content: center;
  align-items: baseline;
  padding: 0;
  gap: 6px;
  img {
    cursor: pointer !important;
  }
  & span {
    font-weight: 700;
    font-size: 16px;
    color: #000;
  }
`;

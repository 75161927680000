import { usePostFile } from '@storelink-io/storelink-core/api/hooks';
import type { IPostFileResponse, IPostFileRequest } from '@/api/interface';
import type {
  IPostRawFileUploadRequest,
  IPostRawFileUploadResponse,
} from '@/api/interface/IAdResult';
import { apiRoutes } from 'routes';

export const usePostFileUpload = () => {
  return usePostFile<IPostFileRequest, IPostFileResponse>(
    apiRoutes.postFileUpload
  );
};

export const usePostSalesRawFileUpload = () => {
  return usePostFile<IPostRawFileUploadRequest, IPostRawFileUploadResponse>(
    apiRoutes.postSalesRawFileUpload
  );
};

export const usePostCampaignRawFileUpload = () => {
  return usePostFile<IPostRawFileUploadRequest, IPostRawFileUploadResponse>(
    apiRoutes.postCampaignRawFileUpload
  );
};

import { Tabs } from '@storelink-io/storelink-ui/base';
import type { TabList } from '@storelink-io/storelink-ui/base/tabs/Tabs';
import { Layout, Menu } from 'antd';
import type { ItemType } from 'antd/lib/menu/hooks/useItems';
import Link from 'next/link';
import { useRouter } from 'next/router';
import type { FC } from 'react';
import { useMemo, useState } from 'react';
import { useGetMemberInfo } from '@/api/hooks';
import {
  cssLeftMenuStyle,
  cssLeftStyle,
  cssLeftTabStyle,
} from '@/components/layout/MainLayout.styles';
import { pageRoutes } from 'routes';
import type { MainLayoutProps } from './MainLayout';

const { Sider } = Layout;

type Props = Required<Pick<MainLayoutProps, 'leftKind'>>;

export const MainLeft: FC<Props> = ({ leftKind }) => {
  const router = useRouter();
  const [activeKey, setActiveKey] = useState(router.pathname);

  const { data: memberInfo } = useGetMemberInfo();

  const items = useMemo(() => {
    return Object.values(pageRoutes[leftKind]).reduce(
      (result, { path, icon, title, memberType }) => {
        const pathname = typeof path === 'function' ? path() : path;
        const userType = memberInfo?.result?.memberTypeCd;

        if ((memberType && memberType === userType) || !memberType) {
          return [
            ...result,
            {
              key: `sider-${pathname}`,
              icon: icon,
              label: <Link href={pathname}>{title}</Link>,
            },
          ];
        } else {
          return result;
        }
      },
      [] as ItemType[]
    );
  }, [leftKind, memberInfo?.result?.memberTypeCd]);

  const tapItems = useMemo(() => {
    return Object.values(pageRoutes[leftKind]).reduce(
      (result, { path, title, memberType }) => {
        const pathname = typeof path === 'function' ? path() : path;
        const userType = memberInfo?.result?.memberTypeCd;

        if ((memberType && memberType === userType) || !memberType) {
          return [
            ...result,
            {
              tabKey: `${pathname}`,
              tabName: <Link href={pathname}>{title}</Link>,
            },
          ];
        } else {
          return result;
        }
      },
      [] as TabList[]
    );
  }, [leftKind, memberInfo?.result?.memberTypeCd]);

  return (
    <>
      <Sider width={200} css={cssLeftStyle}>
        <Menu
          css={cssLeftMenuStyle}
          mode="inline"
          items={items}
          activeKey={`sider-${router.pathname}`}
          selectedKeys={[`sider-${router.pathname}`]}
        />
      </Sider>
      <div css={cssLeftTabStyle}>
        <Tabs
          TabList={tapItems}
          size="small"
          type="line"
          activeKey={activeKey}
          onTabClick={(key) => {
            setActiveKey(key);
          }}
        />
      </div>
    </>
  );
};

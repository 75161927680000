import { StorelinkText } from '@storelink-io/storelink-ui';
import { Layout } from 'antd';
import type { FC } from 'react';
import {
  cssFooterStyle,
  cssFooterStyleWithFullWidth,
} from './MainLayout.styles';

const { Footer } = Layout;

interface IMainFooterProps {
  fullWidth?: boolean;
}
export const MainFooter: FC<IMainFooterProps> = ({ fullWidth }) => {
  return (
    <Footer css={fullWidth ? cssFooterStyleWithFullWidth : cssFooterStyle}>
      <div className="footer-left">
        <div>
          <StorelinkText kind="p3" weight="regular" color="gray1" underline>
            <a
              href="/doc/adlink_privacypolicy.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              개인정보 처리방침
            </a>
          </StorelinkText>

          <StorelinkText kind="p3" weight="regular" color="gray1" underline>
            <a
              href="/doc/adlink_termsofuse.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              이용약관
            </a>
          </StorelinkText>
        </div>
        <div className="tiny-font">
          <StorelinkText kind="p5" weight="medium" color="gray1">
            ADLINK CORPORATION&nbsp;&nbsp;&nbsp; Copyright © 2022, Powered by
            storelink
          </StorelinkText>
        </div>
      </div>
      <div className="footer-right tiny-font">
        <StorelinkText kind="p5" weight="medium" color="gray3">
          상호 : 주식회사 스토어링크&nbsp; 대표 : 정용은 개인정보보호책임자 :
          권기인
          <br />
          서울시 중구 중림로 49 1F 스토어링크
          <br />
          대표 전화 : 02-365-7783&nbsp; 사업자등록번호 : 439-85-01354
        </StorelinkText>
      </div>
    </Footer>
  );
};

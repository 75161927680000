import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { STLCOLOR } from '../../styles/GlobalStyleVariables';
import type { StorelinkTextProps } from './StorelinkText';

export const fontStyle = {
  kind: {
    h1: {
      fontSize: 48,
      lineHeight: 72,
    },
    h2: {
      fontSize: 32,
      lineHeight: 48,
    },
    h3: {
      fontSize: 24,
      lineHeight: 36,
    },
    h4: {
      fontSize: 20,
      lineHeight: 30,
    },
    p1: {
      fontSize: 16,
      lineHeight: 22,
    },
    p2: {
      fontSize: 15,
      lineHeight: 20,
    },
    p3: {
      fontSize: 14,
      lineHeight: 22,
    },
    p4: {
      fontSize: 13,
      lineHeight: 20,
    },
    p5: {
      fontSize: 12,
      lineHeight: 18,
    },
  },
  color: {
    gradient: undefined,
    blue: undefined,
    red: undefined,
    black: undefined,
    white: undefined,
    gray1: undefined,
    gray2: undefined,
    gray3: undefined,
    gray4: undefined,
    green: undefined,
    inherit: undefined,
  },
  weight: {
    bold: 700,
    semiBold: 600,
    medium: 500,
    regular: 400,
  },
} as const;

export const cssSbGridStyle = css`
  .ant-row + .ant-row {
    margin-top: 0px;
  }
  .ant-row {
    border: 1px solid gray;
    border-bottom: 0;
    &:last-child {
      border-bottom: 1px solid gray;
    }
    .ant-col {
      padding: 8px 0 8px 20px;
      border-right: 1px solid gray;

      &:nth-child(5),
      &:nth-child(10),
      &:last-child {
        border-right: 0;
      }
    }
  }
`;

export const makeTextStyles = (
  props: Required<Omit<StorelinkTextProps, 'onClick'>> &
    Pick<StorelinkTextProps, 'onClick'>
) => ({
  marginBottom: 0,
  fontWeight:
    typeof props.weight === 'number'
      ? props.weight
      : fontStyle.weight[props.weight],
  ...(typeof props.kind === 'string'
    ? {
        fontSize: fontStyle.kind[props.kind].fontSize,
        lineHeight: `${fontStyle.kind[props.kind].lineHeight}px`,
      }
    : {
        fontSize: props.kind,
      }),

  ...(props.color === 'gradient'
    ? {
        background: `linear-gradient(270deg, ${STLCOLOR.blue} 3.24%, #625FFF 102.39%)`,
        backgroundClip: 'text',
        textFillColor: 'transparent',
      }
    : {
        color: props.color === 'inherit' ? props.color : STLCOLOR[props.color],
      }),
  ...(props.onClick && {
    cursor: 'pointer',
  }),
});

export const TextSkeletonContainer = styled('div')<{
  width?: string;
  minWidth?: string;
}>`
  width: ${(props) => props.width ?? '100%'};
  min-width: ${(props) => props.minWidth ?? '100%'};
  & > .ant-skeleton {
    width: 100%;
  }
`;

export const StorelinkH1 = styled.h1(makeTextStyles);
export const StorelinkH2 = styled.h2(makeTextStyles);
export const StorelinkH3 = styled.h3(makeTextStyles);
export const StorelinkH4 = styled.h4(makeTextStyles);
export const StorelinkP = styled.span(makeTextStyles);

import { useFetch, usePost } from '@storelink-io/storelink-core/api/hooks';
import type {
  IFraudPostResponse,
  IFraudRequestBody,
  IFraudResponse,
  IFraudsResponse,
} from '@/api/interface';
import { apiRoutes } from '@/routes';

export const useGetFraud = (advFraudNo: string) => {
  return useFetch<IFraudResponse>(
    apiRoutes.getFraud,
    { advFraudNo },
    { enabled: !!advFraudNo }
  );
};

export const usePostFraud = () => {
  return usePost<IFraudRequestBody, IFraudPostResponse>(apiRoutes.postFraud);
};

export const useGetFrauds = () => {
  return useFetch<IFraudsResponse>(apiRoutes.getFrauds);
};

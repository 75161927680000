export const MEMBER_TYPE_CD = {
  GENERAL: { label: '광고주', value: '100' },
  AGENCY: { label: '광고업체', value: '200' },
} as const;

export type MemberTypeCdType =
  (typeof MEMBER_TYPE_CD)[keyof typeof MEMBER_TYPE_CD]['value'];

export const EMAIL_AGREE_YN = {
  YES: { value: 'Y' },
  NO: { value: 'N' },
} as const;

export type EmailAgreeYnType =
  (typeof EMAIL_AGREE_YN)[keyof typeof EMAIL_AGREE_YN]['value'];

export const FIND_USERINFO_TYPE = {
  ID: { label: '이메일 찾기', value: 'email' },
  PW: { label: '비밀번호 찾기', value: 'pw' },
} as const;

export type FindUserinfoTypeValue =
  (typeof FIND_USERINFO_TYPE)[keyof typeof FIND_USERINFO_TYPE]['value'];

import { Layout } from 'antd';
import type { FC, PropsWithChildren } from 'react';
import { AdLinkLogo } from '@/components/logo/AdLinkLogo';
import { cssLoginContentStyle, cssLoginLayoutStyle } from './MainLayout.styles';

export const LoginLayout: FC<PropsWithChildren> = ({ children }) => (
  <Layout css={cssLoginLayoutStyle}>
    <main css={cssLoginContentStyle}>
      <AdLinkLogo width={82} height={25} />
      {children}
    </main>
  </Layout>
);

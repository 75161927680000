import { HOST_API } from '@/api/hosts';

export const apiRoutes = {
  postSmsAuthRequest: `${HOST_API}/adl/common/crtf/request`,
  postSmsAuthConfirm: `${HOST_API}/adl/common/crtf/confirm`,
  getCompanyList: `${HOST_API}/adl/corporates`,
  postMemberRegisters: `${HOST_API}/adl/member/register`,
  getMemberMemberids: `${HOST_API}/adl/member/memberids`,
  postMemberPassword: `${HOST_API}/adl/member/password`,
  postFileUpload: `${HOST_API}/adl/common/file/upload`,
  getAdvertiseCorporates: `${HOST_API}/adl/adv/corporates`,
  getAdvertiseCorporateDetail: `${HOST_API}/adl/adv/corporate`,
  getAdvertiseCorporateReviews: `${HOST_API}/adl/adv/corporate/reviews`,
  getFraud: `${HOST_API}/adl/adv/corporate/fraud`,
  postFraud: `${HOST_API}/adl/adv/corporate/fraud`,
  getFrauds: `${HOST_API}/adl/adv/corporate/frauds`,
  getMemberInfo: `${HOST_API}/adl/member/info`,
  getCommonCodes: `${HOST_API}/adl/common/codes`,
  getCommonCode: `${HOST_API}/adl/common/code`,
  postAdvertiseCorporateReview: `${HOST_API}/adl/adv/corporate/review`,
  postMemberWithdraw: `${HOST_API}/adl/member/withdraw`,
  postMemberMobile: `${HOST_API}/adl/member/mobile`,
  postMemberEmail: `${HOST_API}/adl/member/email`,
  getMemberCorporate: `${HOST_API}/adl/member/corporate`,
  postMemberCorporate: `${HOST_API}/adl/member/corporate`,
  postMemberConfirm: `${HOST_API}/adl/member/confirm`,
  getMemberCorporateReviews: `${HOST_API}/adl/member/corporate/reviews`,
  postMemberCorporateReview: `${HOST_API}/adl/member/corporate/review/delete`,
  getAdResultByDate: `${HOST_API}/adl/ad/result`,
  getAllDayAdResults: `${HOST_API}/adl/ad/result/date`,
  postAdResultByDate: `${HOST_API}/adl/ad/result`,
  postSalesRawFileUpload: `${HOST_API}/adl/ad/raw/sales`,
  postCampaignRawFileUpload: `${HOST_API}/adl/ad/raw/campaign`,
  dashboardSummary: `${HOST_API}/adl/ad/dashboard/summary`,
  dashboardSales: `${HOST_API}/adl/ad/dashboard/sales`,
  dashboardCampaignPerformance: `${HOST_API}/adl/ad/dashboard/campaign/performance`,
  dashboardSalesPerformance: `${HOST_API}/adl/ad/dashboard/sales/performance`,
} as const;

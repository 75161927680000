import { css } from '@emotion/react';

export const cssAntdButtonStyle = css`
  &.ant-btn {
    border-radius: 5px;
    //min-width: 108px;
    min-width: 81px;
    height: 32px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;

    &.ant-btn-primary {
      &.ant-btn-primary:hover,
      &.ant-btn-primary:focus {
      }
      &.ant-btn-dangerous {
        &.ant-btn-dangerous.ant-btn-primary:hover,
        &.ant-btn-dangerous.ant-btn-primary:focus {
        }
      }
    }

    &.ant-btn-default {
      &.ant-btn-default:hover,
      &.ant-btn-default:focus {
      }

      &.ant-btn-dangerous {
        &.ant-btn-dangerous.ant-btn-default:hover,
        &.ant-btn-dangerous.ant-btn-default:focus {
        }
      }
    }

    &.ant-btn-link {
      font-weight: 400;
      &.ant-btn-link:hover,
      &.ant-btn-link:focus {
      }
      &.ant-btn-lg {
        font-weight: 600;
      }

      &.ant-btn-dangerous.ant-btn-link:hover,
      &.ant-btn-dangerous.ant-btn-link:focus {
      }
    }

    &.ant-btn-text {
      font-weight: 500;
      &.ant-btn-text:hover,
      &.ant-btn-text:focus {
        border-radius: 5px;
      }
      &.ant-btn-lg {
        font-weight: 600;
      }

      &.ant-btn-dangerous {
        &.ant-btn-dangerous.ant-btn-text:hover,
        &.ant-btn-dangerous.ant-btn-text:focus {
        }
      }
    }

    &.ant-btn-lg {
      min-width: 340px;
      height: 54px;
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
    }
    &.ant-btn-sm {
      min-width: 92px;
      height: 28px;
      line-height: 22px;
    }

    &.ant-btn-primary[disabled],
    &.ant-btn-primary[disabled]:hover,
    &.ant-btn-primary[disabled]:focus,
    &.ant-btn-primary[disabled]:active,
    &.ant-btn[disabled],
    &.ant-btn[disabled]:hover,
    &.ant-btn[disabled]:focus,
    &.ant-btn[disabled]:active {
      &.ant-btn-dangerous:hover,
      &.ant-btn-dangerous:focus,
      &.ant-btn-dangerous:active {
      }
    }

    &.ant-btn-link[disabled],
    &.ant-btn-link[disabled]:hover,
    &.ant-btn-link[disabled]:focus,
    &.ant-btn-link[disabled]:active,
    &.ant-btn-text[disabled],
    &.ant-btn-text[disabled]:hover,
    &.ant-btn-text[disabled]:focus,
    &.ant-btn-text[disabled]:active {
      background: none;
      border: none;

      &.ant-btn-dangerous:hover,
      &.ant-btn-dangerous:focus,
      &.ant-btn-dangerous:active {
        background: none;
        border: none;
      }
    }

    &.ant-btn.ant-btn-background-ghost,
    &.ant-btn.ant-btn-background-ghost:hover,
    &.ant-btn.ant-btn-background-ghost:active,
    &.ant-btn.ant-btn-background-ghost:focus {
      background: transparent;
    }

    &.ant-btn-background-ghost.ant-btn-primary:hover,
    &.ant-btn-background-ghost.ant-btn-primary:focus {
    }

    &.ant-btn-block {
      min-width: 0;
      width: 100%;
    }

    &.ant-btn.ant-btn-icon-only {
      width: 32px;
      height: 32px;
      min-width: 0;
      &.ant-btn-sm {
        width: 16px;
        height: 16px;
      }
    }
    &.ant-btn.ant-btn-sm {
      display: inline-flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .anticon {
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
`;

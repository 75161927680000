import type { AxiosError } from 'axios';
import {
  useQuery as useOriginalQuery,
  useQueries as useOriginalQueries,
  useMutation as useOriginalMutation,
  useInfiniteQuery,
  useQueryClient,
} from 'react-query';
import type { QueryFunction, QueryKey, UseQueryOptions } from 'react-query';
import type { QueryFunctionContext } from 'react-query/types/core/types';
import type { IResponse } from '../interface';
import { api } from '../request';

type TQueryFunction<T> = QueryFunction<T, QueryKey>;
type TOptions<T> = Omit<UseQueryOptions<T>, 'queryKey' | 'queryFn'>;

export const useQuery = <T>(
  queryKey: QueryKey, // string | unknown[]
  queryFn: TQueryFunction<T>, // <T>() => Promise<T>
  options?: TOptions<T>
) => {
  return useOriginalQuery(queryKey, queryFn, options);
};

export { useOriginalMutation as useMutation };

export interface GetInfinitePagesInterface<T> extends IResponse {
  result?: T[];
}

type QueryKeyT = [string, object | undefined];
type QueryFunctionContextT = Omit<QueryFunctionContext<QueryKeyT>, 'meta'> &
  Partial<Pick<QueryFunctionContext<QueryKeyT>, 'meta'>>;

const makeMultipartFormData = <T>(data: T) => {
  const formData = new FormData();
  if (data) {
    Object.entries(data).forEach(([k, v]) => {
      if (k === 'file' || v instanceof File) {
        formData.append(k, v as File);
      } else if (typeof v === 'string') {
        formData.append(k, v);
      } else {
        formData.append(
          k,
          new Blob([JSON.stringify(v)], { type: 'application/json' })
        );
      }
    });
  }
  return formData;
};

export const fetcher = <T>({
  queryKey,
  pageParam,
}: QueryFunctionContextT): Promise<T> => {
  const [url, params] = queryKey;
  return api.get<T>(url, {
    params: { ...params, ...(pageParam && { page: pageParam }) },
  });
};

export const useLoadMore = <T>(url: string | null, params?: object) => {
  const context = useInfiniteQuery<
    GetInfinitePagesInterface<T>,
    Error,
    GetInfinitePagesInterface<T>,
    QueryKeyT
  >(
    [url!, params],
    ({ queryKey, pageParam = 1 }) => fetcher({ queryKey, pageParam }),
    {
      getPreviousPageParam: (firstPage) =>
        firstPage.page
          ? firstPage.page.pageNum > 1
            ? firstPage.page.pageNum - 1
            : undefined
          : undefined,
      getNextPageParam: (lastPage) => {
        return lastPage.page
          ? lastPage.page.pageNum < lastPage.page.totalPage
            ? lastPage.page.pageNum + 1
            : undefined
          : undefined;
      },
    }
  );

  return context;
};

export const usePrefetch = <T>(url: string | null, params?: object) => {
  const queryClient = useQueryClient();

  return () => {
    if (!url) {
      return;
    }

    queryClient.prefetchQuery<T, Error, T, QueryKeyT>(
      [url!, params],
      ({ queryKey }) => fetcher({ queryKey })
    );
  };
};

export const useFetch = <T>(
  url: string | null,
  params?: object,
  config?: UseQueryOptions<T, Error, T, QueryKeyT>
) => {
  const context = useOriginalQuery<T, Error, T, QueryKeyT>(
    [url!, params],
    ({ queryKey }) => fetcher({ queryKey }),
    {
      enabled: !!url,
      ...config,
    }
  );

  return context;
};

export const useParallelFetch = <T>(
  queriesParams: {
    url: string | null;
    params?: object;
    config?: UseQueryOptions<T, Error, T, QueryKeyT>;
  }[]
) => {
  const queries: {
    queryKey: QueryKeyT;
    queryFn: QueryFunction<T, QueryKeyT>;
    config: UseQueryOptions<T, Error, T, QueryKeyT>;
  }[] = queriesParams.map(({ url, params, config }) => ({
    queryKey: [url!, params],
    queryFn: ({ queryKey }) => fetcher({ queryKey }),
    config: {
      ...config,
    },
  }));
  const context = useOriginalQueries(queries);
  return context;
};

/**
 *
 * @param url
 * @param params
 * @param config
 */
export const useFetchDownload = <T>(
  url: string | null,
  params?: object,
  config?: UseQueryOptions<T, Error, T, QueryKeyT>
) => {
  const context = useOriginalQuery<T, Error, T, QueryKeyT>(
    [url!, params],
    ({ queryKey }) => {
      const [url, params] = queryKey;
      return api.get<T>(url, {
        params: { ...params },
        responseType: 'blob',
      });
    },
    {
      enabled: !!url,
      ...config,
    }
  );

  return context;
};

const useGenericMutation = <T, S, F = T>(
  func: (data: T | F | S) => Promise<S>,
  url: string,
  params?: object
  // updater?: ((oldData: T, newData: S) => T) | undefined
) => {
  const queryClient = useQueryClient();

  return useOriginalMutation<S, AxiosError, T | F | S>(func, {
    onMutate: async (_data) => {
      await queryClient.cancelQueries([url!, params]);

      const previousData = queryClient.getQueryData([url!, params]);

      // queryClient.setQueryData<T>([url!, params], (oldData) => {
      //   return updater ? updater(oldData!, data as S) : (data as T);
      // });

      return previousData;
    },
    onError: (err, _, context) => {
      queryClient.setQueryData([url!, params], context);
    },
    onSettled: () => {
      queryClient.invalidateQueries([url!, params]);
    },
  });
};

export const useDelete = <T>(
  url: string,
  params?: object
  // updater?: (oldData: T, id: string | number) => T
) => {
  return useGenericMutation<T, string | number>(
    (id) => api.delete(`${url}/${id}`),
    url,
    params
    // updater
  );
};

export const usePost = <T, S>(
  url: string,
  params?: object
  // updater?: (oldData: T, newData: S) => T
) => {
  return useGenericMutation<T, S>(
    (data) => api.post<S>(url, data),
    url,
    params
    // updater
  );
};

export const usePostDownload = <T, S>(
  url: string,
  onSettled: (
    response: S | undefined,
    error: AxiosError | null,
    variables: T
  ) => void,
  onError?: () => void
) => {
  return useOriginalMutation<S, AxiosError, T>(
    (data) => api.postDownload<S>(url, data),
    {
      onSettled,
      onError,
    }
  );
};

export const usePostFile = <T, S>(
  url: string,
  params?: object
  // updater?: (oldData: T, newData: S) => T
) => {
  return useGenericMutation<T, S, FormData>(
    (data) => {
      return api.postFile<S>(url, makeMultipartFormData(data));
    },
    url,
    params
    // updater
  );
};

export const usePut = <T, S>(
  url: string,
  params?: object
  // updater?: (oldData: T, newData: S) => T
) => {
  return useGenericMutation<T, S>(
    (data) => api.put<S>(url, data),
    url,
    params
    // updater
  );
};
export const usePutFile = <T, S>(
  url: string,
  params?: object
  // updater?: (oldData: T, newData: S) => T
) => {
  return useGenericMutation<T, S, FormData>(
    (data) => {
      return api.putFile<S>(url, makeMultipartFormData(data));
    },
    url,
    params
    // updater
  );
};

export const useUpdate = <T, S>(
  url: string,
  params?: object
  // updater?: (oldData: T, newData: S) => T
) => {
  return useGenericMutation<T, S>(
    (data) => api.patch<S>(url, data),
    url,
    params
    // updater
  );
};
